export const getters = {
  getUserProfile(state) {
    return state.userProfile;
  },

  /** User management **/
  getUsers(state) {
    return state.users;
  }
}
