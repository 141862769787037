import JiraProject from "@/models/jira/JiraProject";
import Employee from "@/models/employee/Employee";
import JiraIssue from "@/models/jira/JiraIssue";
import JiraDepartment from "@/models/jira/JiraDepartment";

/** Jira projects **/
export const SET_JIRA_PROJECTS = 'setJiraProjects';
export const CLEAR_JIRA_PROJECTS = 'clearJiraProjects';
export const SET_SEARCH_EMPLOYEES_RESULTS = 'setSearchEmployeesResults';
export const CLEAR_SEARCH_EMPLOYEES_RESULTS = 'clearSearchEmployeesResults';
export const SET_SEARCH_ISSUES_RESULTS = 'setSearchIssuesResults';
export const CLEAR_SEARCH_ISSUES_RESULTS = 'clearSearchIssuesResults';

/** Other **/
export const SET_DEPARTMENTS = 'setDepartments';

export const mutations = {

  /** Jira projects **/
  setJiraProjects(state, data) {
    state.jiraProjects = data.map(item => JiraProject.parseDataFromObject(item));
  },
  clearJiraProjects(state) {
    state.jiraProjects = [];
  },
  setSearchEmployeesResults(state, data) {
    state.searchEmployeesResults = data.map(employee => Employee.parseDataFromObject(employee));
  },
  clearSearchEmployeesResults(state) {
    state.searchEmployeesResults = [];
  },
  setSearchIssuesResults(state, data) {
    state.searchIssuesResults = data.map(employee => JiraIssue.parseDataFromObject(employee));
  },
  clearSearchIssuesResults(state) {
    state.searchIssuesResults = [];
  },

  /** Other **/
  setDepartments(state, data) {
    state.departments = data.map(item => JiraDepartment.parseDataFromObject(item));
  }
}
