import {formatIntoDashedDMY} from "@/utils/dateManipulation";

export default class User {
  constructor({createdAt, email, firstName, id, lastName, roleId, roleName}) {
    this.createdAt = createdAt;
    this.email = email;
    this.firstName = firstName;
    this.id = id;
    this.lastName = lastName;
    this.roleId = roleId;
    this.roleName = roleName;
  }

  get creationDate() {
    if (!this.createdAt) {
      return 'N/A'
    }
    return formatIntoDashedDMY(new Date(this.createdAt));
  }

  get username() {
    return `${this.firstName} ${this.lastName}`;
  }

  static parseDataFromObject(data) {
    return new User(data);
  }
}