import LocalDataHandler from '@/utils/handlers/LocalDataHandler';
import apiHandler from '@/utils/handlers/ApiHandler';
import {LOGIN_FAILURE, LOGIN_SUCCESS, SET_LOGOUT} from "./mutations";

export const SEND_LOGIN = 'sendLogin';
export const RESET_PASSWORD = 'resetPassword';
export const SEND_FORGOT_PASSWORD = 'sendForgotPassword';

export const actions = {
  sendLogin({commit}, data) {
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', process.env.VUE_APP_AUTH_CLIENT_ID);
    formData.append('username', data.email);
    formData.append('password', data.password);
    formData.append('client_secret', '');

    return apiHandler.post('auth/login', formData).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(LOGIN_SUCCESS, response.data);
        apiHandler.setAuthHandler(true);
        LocalDataHandler.setAuthenticated();
        return true;
      }
      return false;
    }).catch(error => {
      console.error(error);
      commit(LOGIN_FAILURE, error);
      return false;
    });
  },
  resetPassword({commit}, {id, token, password}) {
    const data = {password: password};
    return apiHandler.post(`auth/reset-password/${id}/${token}`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      console.error(error);
      commit(LOGIN_FAILURE, error);
      return false;
    });
  },
  sendForgotPassword({commit}, data) {
    return apiHandler.post('auth/forgot-password', data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      console.error(error);
      commit(LOGIN_FAILURE, error);
      return false;
    });
  },
  logout({commit}) {
    return apiHandler.post('auth/logout').then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_LOGOUT, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      console.error(error);
      commit(LOGIN_FAILURE, error);
      return false;
    });
  }
}
