/** Order type **/
import OrderTypeOption from "../../models/order/OrderTypeOption";
import OrderDetails from "@/models/order/OrderDetails";

/** Order type **/
export const SET_ORDER_TYPES = 'setOrderTypes';

/** Order details **/
export const SET_SELECTED_ORDER = 'setSelectedOrder';
export const RESET_SELECTED_ORDER = 'resetSelectedOrder';

export const mutations = {
  /** Order type **/
  setOrderTypes(state, data) {
    state.orderTypes = data.map(type => OrderTypeOption.parseDataFromObject(type));
  },

  /** Order details **/
  setSelectedOrder(state, data) {
    state.selectedOrder = OrderDetails.parseDataFromObject(data);
  },
  resetSelectedOrder(state) {
    state.selectedOrder = null;
  },
}
