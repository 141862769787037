import Employee from "../employee/Employee";
import {EXPORT_PRESETS} from "../order/Worklog";

export default class Log {
  constructor({id, date, employee, excludeLog, excludeReason, issue, summary, comment, hours, ratio}) {
    this.id = id;
    this.date = date;
    this.employee = employee ? Employee.parseDataFromObject(employee) : {};
    this.excludeLog = excludeLog;
    this.excludeReason = excludeReason; // null = no change, "" = there was a change but no specified reason, "a reason" = there was a change and specified reason
    this.issue = {
      id: issue.id,
      key: issue.key,
      link: issue.link,
      summary: issue.summary
    };
    this.summary = summary;
    this.comment = comment;
    this.hours = hours;
    this.ratio = ratio;
  }

  /**
   * Reason
   */

  // A log is unchanged when Ratio = 100
  get isUnchanged() {
    if (this.ratio === 100) {
      return true;
    }
    return this.excludeReason === null;
  }

  get isChangedWithoutReason() {
    return this.excludeReason === "";
  }

  /**
   * Employee
   */
  get employeeId() {
    return this.employee.id;
  }

  setEmployeeId(id) {
    this.employee.id = id;
  }

  /**
   * Issue
   */
  get issueLink() {
    return this.issue.link;
  }

  get issueKey() {
    return this.issue.key;
  }

  get issueId() {
    return this.issue.id;
  }

  get issueSummary() {
    return this.issue.summary;
  }

  static parseDataFromObject(data) {
    return new Log(data);
  }
}
