import apiHandler from "@/utils/handlers/ApiHandler";
import {SET_API_ERROR, SET_PAGINATION_DATA} from "@/store/mutations";
import {SET_ACTIVE_ORDERS} from "@/store/activeOrders/mutations";

export const GET_ACTIVE_ORDERS = 'getActiveOrders';

export const actions = {
  getActiveOrders({commit}, {page, search}) {
    return apiHandler.get(`projects/active-billable-orders?page=${page}&search=${search}`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        const data = response.data;
        commit(SET_ACTIVE_ORDERS, data.data);
        commit(SET_PAGINATION_DATA, {totalPages: data.totalPages, currentPage: data.currentPage});
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    });
  },
}
