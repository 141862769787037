import Project from "@/models/project/Project";
import Order from "@/models/order/Order";
import {isNumberFloat} from "@/utils/globals";

export default class ProjectDetails extends Project {
  constructor({id, name, orders, remainingHours, totalAvailableHours, totalProcessedHours, totalReductedHours, totalSpentHours, totalUnassignedHours, totalUnassignedLogs, jiraProjects = null}) {
    super({id, name, jiraProjects});
    this.orders = orders.map(order => Order.parseDataFromObject(order));
    this.remainingHours = remainingHours;
    this.totalReductedHours = totalReductedHours;
    this.totalAvailableHours = totalAvailableHours;
    this.totalProcessedHours = totalProcessedHours;
    this.totalSpentHours = totalSpentHours;
    this.totalUnassignedHours = totalUnassignedHours;
    this.totalUnassignedLogs = totalUnassignedLogs;
  }

  get remainingHoursRounded() {
    return this.remainingHours.toFixed(2);
  }

  get totalAvailableHoursRounded() {
    return this.totalAvailableHours.toFixed(2);
  }

  get totalSpentHoursRounded() {
    return this.totalSpentHours.toFixed(2);
  }

  // get availableHours() {
  //   return isNumberFloat(this.totalAvailableHours) ?  this.totalAvailableHours.toFixed(2) : this.totalAvailableHours;
  // }
  //
  // get totalRemainingHours() {
  //   return isNumberFloat(this.remainingHours) ?  this.remainingHours.toFixed(2) : this.remainingHours;
  // }

  /** Orders **/
  get completedOrders() {
    return this.orders.filter(order => order.completed);
  }

  get ongoingOrders() {
    return this.orders.filter(order => !order.completed);
  }

  get hasOrders() {
    return this.orders.length > 0;
  }

  static parseDataFromObject(data) {
    return new ProjectDetails(data);
  }
}
