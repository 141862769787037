export const LOG_CONFLICT_CHANGES = {
  COMMENT: 'comment',
  HOURS: 'hours',
  DELETED: 'deleted'
}

export const ISSUE_CONFLICT_CHANGES = {
  SUMMARY: 'summary',
  PARENT: 'parentKey',
}

export default class ConflictChange {
  constructor(change) {
    this.change = change;
  }

  static parseDataFromObject(data) {
    return new ConflictChange(data);
  }
}
