/** routes **/
import {auth} from '@/router/modules/auth'
import {projectOverview} from "./projectOverview";
import {order} from "@/router/modules/order";
import {manageLogs} from "@/router/modules/manageLogs";
import {conflictManager} from "@/router/modules/conflictManager";
import {activeOrders} from "@/router/modules/activeOrders";
import {users} from "@/router/modules/users";
import {calculations} from "@/router/modules/calculations";

export const ROUTE_NAMES_ROOT = Object.freeze({});

/** routes without layout **/
export const root = []

export default root.concat([
  auth,
  projectOverview,
  order,
  manageLogs,
  conflictManager,
  activeOrders,
  users,
  calculations
]);
