export const getters = {

  /** Logs **/
  getConflictedLogs(state) {
    return state.conflictedLogs;
  },

  /** Logs **/
  getConflictedIssues(state) {
    return state.conflictedIssues;
  },
}
