import AuthGuard from '../guards/auth';

const Layout = () => import('@/views/Layout');
const ProjectOverview = () => import('@/views/projectOverview/ProjectOverview.vue');

export const ROUTE_NAMES_PROJECT_OVERVIEW = Object.freeze({
  OVERVIEW: 'ProjectOverview',
});

export const projectOverview = {
  path: '/project-overview',
  name: 'Overview',
  component: Layout,
  meta: {
    guard: AuthGuard
  },
  children: [
    {
      path: 'home',
      name: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW,
      meta: {
        // guard: ...
      },
      component: ProjectOverview
    },
  ]
};
