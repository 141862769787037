<template>
  <div :id="toastId" :class="toast.options.wrapperClass" class="toast-wrapper">

    <div class="toast-content">

      <!-- Icon -->
      <img v-if="toast.icon" :src="toast.icon" class="icon" alt=""/>

      <!-- Text -->
      <div class="text-wrapper">
        <p class="title">{{ toast.title }}</p>
        <p v-if="toast.subtitle" v-html="toast.subtitle" class="subtext"></p>
        <slot name="subtext"/>
      </div>

      <!-- Close -->
      <img @click="close()" src="../../../assets/icons/svg/ic_close_light_grey.svg" class="close-icon" alt="close"/>
    </div>

  </div>
</template>

<script>
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import Toast from "@/utils/handlers/toastHandler/Toast";

export default {
  name: "Toast",
  props: {
    toast: {
      type: Toast,
      required: true
    },
    debounce: {
      default: 5000
    },
  },
  setup(props) {
    const toastId = `toast${props.toast.id}`;

    if (props.toast.options.autoClose) {
      initializeTimeout();
    }

    function initializeTimeout() {
      setTimeout(() => {
        close();
      }, props.debounce);
    }

    function close() {
      ToastHandler.closeToastById(props.toast.id);
    }

    return {
      toastId,
      close,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/base.variables";

.toast-wrapper {
  background-color: white;
  border-radius: rem(5);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.25);
  border-left: rem(8) solid var(--green-light);
  margin-bottom: rem(20);
  max-width: rem(440);
  padding: rem(16) rem(24);
  transition: 0.3s all ease-in-out;

  .toast-content {
    align-items: center;
    display: flex;
    position: relative;
  }

  .icon {
    height: rem(24);
    margin-right: rem(24);
    width: rem(24);
  }

  .close-icon {
    @include hover-active-pointer();
    height: rem(24);
    margin-left: auto;
    width: rem(24);
  }
}

// Toast - Not allowed
.not-allowed-toast {
  border-left: rem(8) solid var(--red-light);
}

// Text
.text-wrapper {
  margin-right: rem(34);
  text-align: left;

  .title {
    font-size: rem(17);
    font-weight: bold;
    line-height: rem(20);
    margin-bottom: rem(2);
  }

  .subtext {
    color: var(--gray-light-03);
    font-family: var(--open-sans);
    font-size: rem(16);
    font-weight: normal;
    line-height: rem(22);

    ::v-deep a {
      text-align: left;
    }
  }
}
</style>
