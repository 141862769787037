import SuperAdminGuard from '@/router/guards/superAdmin'

// Components imports
const Layout = () => import('@/views/Layout');
const UsersOverview = () => import('@/views/users/UsersOverview');

export const ROUTE_NAMES_USERS = Object.freeze({
  USERS_OVERVIEW: 'UsersOverview',
});

export const users = {
  path: '/users',
  name: 'Users',
  component: Layout,
  meta: {
    guard: SuperAdminGuard // The user management is available to the super admin ONLY.
  },
  children: [
    {
      path: 'overview',
      name: ROUTE_NAMES_USERS.USERS_OVERVIEW,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: null,

        breadcrumb: null,
      },
      component: UsersOverview
    },
  ]
};
