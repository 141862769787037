import OrderConflictedLogs from "@/models/conflictManager/order/OrderConflictedLogs";
import OrderConflictedIssue from "@/models/conflictManager/order/OrderConflictedIssue";

/** Logs **/
export const SET_CONFLICTED_LOGS = 'setConflictedLogs';

/** Issues **/
export const SET_CONFLICTED_ISSUES = 'setConflictedIssues';


export const mutations = {
  /** Logs **/
  setConflictedLogs(state, logs) {
    state.conflictedLogs = logs.map(log => OrderConflictedLogs.parseDataFromObject(log));
  },

  /** Issues **/
  setConflictedIssues(state, issues) {
    state.conflictedIssues = issues.map(issue => OrderConflictedIssue.parseDataFromObject(issue));
  },
}
