import {formatIntoDayMonthYear} from "@/utils/dateManipulation";
import Employee from "@/models/employee/Employee";

export const EXPORT_PRESETS = {INCLUDE_HOURS: 1, EXCLUDE_HOURS: 2, EXCLUDE_LOG: 3,}

export default class Worklog {
  constructor({comment, date, employee, excludeLog, excludeReason, exportPreset, hasConflict, hours, id, issue, ratio, summary}) {
    this.comment = comment;
    this.date = date;
    this.employee = employee ? Employee.parseDataFromObject(employee) : null;
    this.excludeLog = excludeLog;
    this.excludeReason = excludeReason;
    this.exportPreset = exportPreset;
    this.hasConflict = hasConflict;
    this.hours = hours;
    this.id = id;
    this.issue = {
      id: issue.id, key: issue.key, link: issue.link, summary: issue.summary,
    };
    this.ratio = ratio;
    this.summary = summary;
  }

  get excludedHours() {
    if (this.exportPreset !== EXPORT_PRESETS.INCLUDE_HOURS) {
      return this.hours;
    }
    return 0;
  }

  /**
   * Reason
   */

  // A log is unchanged when Ratio = 100 && Export preset = 'Included
  get isUnchanged() {
    if (this.ratio === 100 && this.exportPreset === EXPORT_PRESETS.INCLUDE_HOURS) {
      return true;
    }
    return this.excludeReason === null;
  }

  get isChangedWithoutReason() {
    return this.excludeReason === "";
  }

  /**
   * Issue
   */
  get issueLink() {
    return this.issue.link;
  }

  get issueKey() {
    return this.issue.key;
  }

  get issueId() {
    return this.issue.id;
  }

  get issueSummary() {
    return this.issue.summary;
  }

  /**
   * Employee
   */
  setEmployeeId(id) {
    this.employee.id = id;
  }

  get employeeName() {
    return this.employee.displayName;
  }

  get employeeId() {
    return this.employee.id;
  }

  /** UI Helpers **/
  get formattedCreatedAt() {
    const fixedDate = this.date.replace(/-/g, '/') // Fixed for Safari.
    return formatIntoDayMonthYear(new Date(fixedDate));
  }

  static parseDataFromObject(data) {
    return new Worklog(data);
  }
}
