import {ROUTE_NAMES_PROJECT_OVERVIEW} from "@/router/modules/projectOverview";
import AuthGuard from "../guards/auth";

const Layout = () => import('@/views/Layout');
const OrderOverview = () => import('@/views/order/OrderOverview');
const OrderDetails = () => import('@/views/order/OrderDetails');
const OrderProcessed = () => import('@/views/order/OrderProcessed');

export const ROUTE_NAMES_ORDER = Object.freeze({
  ORDER_OVERVIEW: 'OrderOverview',
  ORDER_DETAILS: 'OrderDetails',
  ORDER_PROCESSED: 'OrderProcessed',
});

export const order = {
  path: '/project/:projectId/order',
  name: 'Order',
  component: Layout,
  meta: {
    guard: AuthGuard
  },
  children: [
    {
      path: 'overview',
      name: ROUTE_NAMES_ORDER.ORDER_OVERVIEW,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW,

        breadcrumb: [
          {
            name: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW,
            text: 'project overview'
          },
          {
            name: ROUTE_NAMES_ORDER.ORDER_OVERVIEW,
            text: 'project'
          },
        ],
      },
      component: OrderOverview
    },
    {
      path: 'overview/details/:orderId',
      name: ROUTE_NAMES_ORDER.ORDER_DETAILS,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_ORDER.ORDER_OVERVIEW,

        breadcrumb: [
          {
            name: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW,
            text: 'project overview'
          },
          {
            name: ROUTE_NAMES_ORDER.ORDER_OVERVIEW,
            textWithName: (projectName = "") => `project: ${projectName}`
          },
          {
            name: ROUTE_NAMES_ORDER.ORDER_DETAILS,
            text: 'order'
          },
        ],
      },
      component: OrderDetails
    },
    {
      path: 'overview/order-processed',
      name: ROUTE_NAMES_ORDER.ORDER_PROCESSED,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_ORDER.ORDER_OVERVIEW,

        breadcrumb: [
          {
            name: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW,
            text: 'project overview'
          },
          {
            name: ROUTE_NAMES_ORDER.ORDER_OVERVIEW,
            textWithName: (projectName = "") => `project: ${projectName}`
          },
          {
            name: ROUTE_NAMES_ORDER.ORDER_PROCESSED,
            text: 'order'
          },
        ],
      },
      component: OrderProcessed
    },
  ]
};
