import ConflictChange, {LOG_CONFLICT_CHANGES} from "@/models/conflictManager/change/ConflictChange";

export default class LogConflictChange extends ConflictChange {
  constructor(change) {
    super(change);
  }

  get changeClass() {
    return `change-${LOG_CONFLICT_CHANGES[this.change]}`;
  }

  static parseDataFromObject(data) {
    return new LogConflictChange(data);
  }
}
