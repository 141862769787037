import JiraProject from "@/models/jira/JiraProject";
import Order from "@/models/order/Order";

export default class ActiveOrder {
  constructor({id, jiraProjects, name, orders, remainingHours, totalAvailableHours, totalReductedHours, totalSpentHours, totalUnassignedHours, totalUnassignedLogs}) {
    this.id = id;
    this.jiraProjects = jiraProjects ? jiraProjects.map(project => JiraProject.parseDataFromObject(project)) : [];
    this.name = name;
    this.orders = orders.map(order => Order.parseDataFromObject(order));
    this.remainingHours = remainingHours;
    this.totalAvailableHours = totalAvailableHours;
    this.totalReductedHours = totalReductedHours;
    this.totalSpentHours = totalSpentHours;
    this.totalUnassignedHours = totalUnassignedHours;
    this.totalUnassignedLogs = totalUnassignedLogs;
  }

  static create(data) {
    return new ActiveOrder(data);
  }
}