import JiraProject from "../jira/JiraProject";

export default class Project {
  constructor({id, name, jiraProjects}) {
    this.id = id;
    this.name = name;
    this.jiraProjects = jiraProjects ? jiraProjects.map(project => JiraProject.parseDataFromObject(project)) : [];
  }

  static parseDataFromObject(data) {
    return new Project(data);
  }
}
