import apiHandler from "@/utils/handlers/ApiHandler";
import {SET_API_ERROR} from "@/store/mutations";
import {SET_ORDER_TYPES, SET_SELECTED_ORDER} from "@/store/order/mutations";

/** Order type **/
export const GET_ORDER_TYPES = 'getOrderTypes';

/** Orders **/
export const GET_ORDER_BY_ID = 'getOrderById';
export const CREATE_ORDER = 'createOrder';
export const EDIT_ORDER = 'editOrder';
export const DELETE_ORDER = 'deleteOrder';

/** Assign **/
export const ASSIGN_LOGS = 'assignLogs';

/** Employee ratio **/
export const EDIT_EMPLOYEE_RATIO = 'editEmployeeRatio';

/** Export **/
export const ADJUST_EXPORT_PRESETS = 'adjustExportPresets';

export const actions = {
  /** Order type **/
  getOrderTypes({commit}) {
    return apiHandler.get('order-types', apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_ORDER_TYPES, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    });
  },

  /** Orders **/
  getOrderById({commit}, {orderId, searchedText, employees, issues, dateData, departmentData}) {
    const data = {
      "issueIds": issues,
      "employeeIds": employees,
      "departments": departmentData,
    }
    const startDate = dateData?.startDate ? dateData.startDate : "";
    const endDate = dateData?.endDate ? dateData.endDate : "";
    const datesQuery = startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : "";

    return apiHandler.post(`orders/${orderId}?search=${searchedText}${datesQuery}`, data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_SELECTED_ORDER, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  createOrder({commit}, payload) {
    const formData = new FormData();
    formData.append('projectId', payload.projectId);
    formData.append('title', payload.title);
    formData.append('type', payload.orderType);
    formData.append('startDate', payload.startDate);
    if (payload.endDate) {
      formData.append('endDate', payload.endDate);
    }
    formData.append('completed', payload.completed ? 1 : 0);
    formData.append('isBillable', payload.isBillable ? 1 : 0);
    formData.append('availableHours', payload.availableHours ? Number(payload.availableHours).toFixed(2) : '0.00');

    return apiHandler.post(`orders`, formData, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  editOrder({commit}, payload) {
    const data = {
      "title": payload.title,
      "type": payload.orderType,
      "startDate": payload.startDate,
      "completed": payload.completed ? 1 : 0,
      "availableHours": payload.availableHours ? Number(payload.availableHours).toFixed(2) : 0,
      "isBillable": payload.isBillable ? 1 : 0
    }
    if (payload.endDate) {
      data.endDate = payload.endDate;
    }

    return apiHandler.put(`orders/${payload.orderId}`, data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  deleteOrder({commit}, orderId) {
    return apiHandler.delete(`orders/${orderId}`, null, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },


  /** Assign **/
  assignLogs({commit}, payload) {
    const data = {
      logIds: payload.logs.map(log => log.id),
      orderId: payload.orderId
    };

    return apiHandler.put(`orders/${payload.orderId}/assign-logs`, data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },

  /** Employee ratio **/
  editEmployeeRatio({commit}, payload) {
    const orderId = payload.orderId;
    const employeeId = payload.employeeId;

    const formData = new FormData();
    formData.append('startDate', payload.startDate);
    formData.append('endDate', payload.endDate);
    formData.append('deleted[0][id]', payload.issues); // TODO: Array - loop through it
    formData.append('allLogsWithinOrder', payload.allLogsWithinOrder);
    formData.append('everyIssueWithinOrder', payload.allLogsWithinOrder);

    return apiHandler.put(`orders/${orderId}/employees/${employeeId}/ratio`, formData, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },

  /** Export **/
  adjustExportPresets({commit}, payload) {
    const data = {
      logs: payload.logs,
      excludeReason: payload.reason
    }

    return apiHandler.put('logs/adjust-export-presets', data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
}
