import apiHandler from "@/utils/handlers/ApiHandler";
import {SET_API_ERROR, SET_PAGINATION_DATA} from "@/store/mutations";
import {
  SET_ALL_PROJECTS, SET_CONFLICTED_PROJECTS,
  SET_JIRA_PROJECTS,
  SET_ORDERS, SET_SEARCH_ISSUES_RESULTS,
  SET_SELECTED_PROJECT,
  SET_UNASSIGNED_LOGS
} from "@/store/project/mutations";
import {SET_SEARCH_EMPLOYEES_RESULTS} from "./mutations";

/** Projects **/
export const GET_ALL_PROJECTS = 'getAllProjects';
export const GET_PROJECT_BY_ID = 'getProjectById';
export const CREATE_PROJECT = 'createProject';
export const EDIT_PROJECT = 'editProject';
export const DELETE_PROJECT = 'deleteProject';

/** Order **/
export const GET_ORDERS = 'getOrders';

/** Unassigned logs **/
export const GET_UNASSIGNED_LOGS = 'getUnassignedLogs';

/** Conflicted projects **/
export const GET_CONFLICTED_PROJECTS = 'getConflictedProjects';

export const actions = {
  /** Projects **/
  getAllProjects({commit}, payload) {
    const page = payload.page ? payload.page : 1;
    const name = payload.name ? payload.name : '';

    return apiHandler.get(`/projects?page=${page}&search=${name}`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        const data = response.data;
        commit(SET_ALL_PROJECTS, data.data);
        commit(SET_PAGINATION_DATA, {totalPages: data.totalPages, currentPage: data.currentPage});
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  getProjectById({commit}, projectId) {
    return apiHandler.get(`/projects/${projectId}`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_SELECTED_PROJECT, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  createProject({commit}, payload) {
    const formData = new FormData();
    formData.append('name', payload.projectName);
    payload.selectedJiraProjects.map(project => {
      formData.append(`jiraProjectIds[]`, project.id);
    });

    return apiHandler.post('projects', formData, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  editProject({commit}, payload) {
    const data = {
      name: payload.projectName,
      jiraProjectIds: payload.selectedJiraProjects.map(project => project.id)
    }

    return apiHandler.put(`/projects/${payload.projectId}`, data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  deleteProject({commit}, projectId) {
    return apiHandler.delete(`/projects/${projectId}`, null, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },

  /** Order **/
  getOrders({commit}, projectId) {
    return apiHandler.get(`projects/${projectId}/orders`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_ORDERS, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },

  /** Unassigned logs **/
  getUnassignedLogs({commit}, {projectId, searchedText, employees, issues, dateData, departmentData, onlyProcessed = 0}) {
    const data = {
      "issueIds": issues,
      "employeeIds": employees,
      "departments": departmentData,
      "onlyProcessed": onlyProcessed
    }

    if (dateData?.startDate) {
      data.startDate = dateData.startDate;
    }

    if (dateData?.endDate) {
      data.endDate = dateData.endDate;
    }

    return apiHandler.post(`/projects/${projectId}/unassigned-logs?search=${searchedText}`, data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_UNASSIGNED_LOGS, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },

  /** Conflicted projects **/
  getConflictedProjects({commit}) {
    return apiHandler.get('jira-conflicts/projects', apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_CONFLICTED_PROJECTS, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
}
