import LogConflictChange from "@/models/conflictManager/change/LogConflictChange";
import OrderTypeOption from "@/models/order/OrderTypeOption";
import ConflictDetails from "@/models/conflictManager/ConflictDetails";

export default class ConflictedLog {
  constructor({changes, date, employee, id, orderType, details, title}) {
    this.changes = changes.map(change => LogConflictChange.parseDataFromObject(change));
    this.date = date;
    this.employee = employee;
    this.id = id;
    this.details = this.setDetails(details);

    // Local (not included in the API's response.
    this.orderType = OrderTypeOption.parseDataFromObject(orderType);
    this.orderTitle = title;
  }

  setDetails(details) {

    // details is present when the change is not DELETED.
    if (details) {
      return Object.keys(details).map(key =>  ConflictDetails.parseDataFromObject({newVersion: details[key].new, old: details[key].old, type: key, conflictId: this.id}));
    }
    return null;
  }

  static parseDataFromObject(data) {
    return new ConflictedLog(data);
  }
}
