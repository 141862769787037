import Project from "@/models/project/Project";
import JiraProject from "@/models/jira/JiraProject";
import ProjectDetails from "@/models/project/ProjectDetails";
import Employee from "../../models/employee/Employee";
import Log from "../../models/logs/Log";
import UnassignedLogs from "../../models/logs/UnassignedLogs";
import JiraIssue from "@/models/jira/JiraIssue";
import ConflictedProject from "@/models/project/ConflictedProject";

/** Projects **/
export const SET_ALL_PROJECTS = 'setAllProjects';
export const SET_SELECTED_PROJECT = 'setSelectedProject';
export const RESET_SELECTED_PROJECT = 'resetSelectedProject';

/** Order **/
export const SET_ORDERS = 'setOrders';

/** Unassigned logs **/
export const SET_UNASSIGNED_LOGS = 'setUnassignedLogs';

/** Conflicted projects **/
export const SET_CONFLICTED_PROJECTS = 'setConflictedProjects';

export const mutations = {
  /** Projects **/
  setAllProjects(state, data) {
    state.projects = data.map(project => Project.parseDataFromObject(project));
  },
  setSelectedProject(state, data) {
    state.selectedProject = ProjectDetails.parseDataFromObject(data);
  },
  resetSelectedProject(state) {
    state.selectedProject = null;
  },

  /** Order **/
  setOrders(state, data) {
    state.orders = data.map(project => Project.parseDataFromObject(project));
  },

  /** Unassigned logs **/
  setUnassignedLogs(state, data) {
    state.unassignedLogs = UnassignedLogs.parseDataFromObject(data);
  },

  /** Conflicted projects **/
  setConflictedProjects(state, data) {
    state.conflictedProjects = data.map(project => ConflictedProject.parseDataFromObject(project));
  },
}
