import {ROUTE_NAMES_PROJECT_OVERVIEW} from "@/router/modules/projectOverview";
import {ROUTE_NAMES_ORDER} from "@/router/modules/order";
import AuthGuard from '../guards/auth';

const Layout = () => import('@/views/Layout');
const ConflictManager = () => import('@/views/conflictManager/ConflictManager');

export const ROUTE_NAMES_CONFLICT_MANAGER = Object.freeze({
  CONFLICT_MANAGER: 'ConflictManager',
});

export const conflictManager = {
  path: '/conflict-manager/',
  component: Layout,
  meta: {
    guard: AuthGuard
  },
  children: [
    {
      path: 'project/:projectId',
      name: ROUTE_NAMES_CONFLICT_MANAGER.CONFLICT_MANAGER,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_ORDER.ORDER_OVERVIEW,

        breadcrumb: [
          {
            name: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW,
            text: 'project overview'
          },
          {
            name: ROUTE_NAMES_ORDER.ORDER_OVERVIEW,
            textWithName: (projectName = "") => `project: ${projectName}`
          },
          {
            name: ROUTE_NAMES_CONFLICT_MANAGER.CONFLICT_MANAGER,
            text: 'jira conflicts'
          },
        ],
      },
      component: ConflictManager
    },
  ]
};
