import apiHandler from "@/utils/handlers/ApiHandler";
import {SET_API_ERROR} from "@/store/mutations";
import {
  SET_DEPARTMENTS,
  SET_JIRA_PROJECTS,
  SET_SEARCH_EMPLOYEES_RESULTS,
  SET_SEARCH_ISSUES_RESULTS
} from "@/store/jira/mutations";

/** Jira projects **/
export const SEARCH_JIRA_PROJECT = 'searchJiraProject';
export const SEARCH_JIRA_EMPLOYEE = 'searchJiraEmployee';
export const SEARCH_JIRA_ISSUES = 'searchJiraIssues';

/** Other **/
export const GET_DEPARTMENTS = 'getDepartments'

export const actions = {

  /** Jira projects **/
  searchJiraProject({commit}, searchTerm) {
    return apiHandler.get(`jira/projects?search=${searchTerm}`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_JIRA_PROJECTS, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  searchJiraEmployee({commit}, searchTerm) {
    return apiHandler.get(`jira/employees?search=${searchTerm}`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_SEARCH_EMPLOYEES_RESULTS, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  searchJiraIssues({commit}, {searchTerm, projectId}) {
    return apiHandler.get(`jira/issues?projectId=${projectId}&search=${searchTerm}`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_SEARCH_ISSUES_RESULTS, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },

  /** Other **/
  getDepartments({commit}) {
    return apiHandler.get('jira/employees/departments', apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_DEPARTMENTS, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
}
