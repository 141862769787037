export default class Employee {
  constructor({id, displayName}) {
    this.id = id;
    this.displayName = displayName;
  }

  /** UI Helpers **/
  get spacelessDisplayName() {
    return this.displayName.replace(/ /g, "");
  }

  // SearchResult
  get searchResultItem() {
    return {name: this.displayName, id: this.id};
  }

  static parseDataFromObject(data) {
    return new Employee(data);
  }
}
