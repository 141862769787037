import UserProfile from "../../models/users/UserProfile";
import User from "../../models/users/UserProfile";
import LocalDataHandler from "@/utils/handlers/LocalDataHandler";

export const SET_USER_PROFILE = 'setUserProfile';

/** User management **/
export const SET_USERS = 'setUsers';
export const mutations = {
  setUserProfile(state, user) {
    state.userProfile = UserProfile.parseDataFromObject(user);
    LocalDataHandler.setUserRole(user.roleId) // Set the userRole
  },

  /** User management **/
  setUsers(state, users) {
    state.users = users.map(user => User.parseDataFromObject(user));
  },
}
