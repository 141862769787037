export const getters = {

  /** Jira projects **/
  getJiraProjects(state) {
    return state.jiraProjects;
  },
  getSearchEmployeesResults(state) {
    return state.searchEmployeesResults;
  },
  getSearchIssuesResults(state) {
    return state.searchIssuesResults;
  },

  /** Other **/
  getDepartments(state) {
    return state.departments;
  },
}
