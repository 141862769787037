<template>
  <div id="app">
    <!-- Loading indicator -->
    <LoadingSpinner v-if="!hideLoadingSpinner" :is-loading="isLoading"/>

    <router-view/>

    <!-- Toasts -->
    <ToastManager/>
  </div>
</template>

<script>
import LoadingSpinner from "./components/elements/LoadingSpinner";
import {computed} from "@vue/composition-api";
import ToastManager from "@/components/elements/toast/ToastManager";

export default {
  components: {
    ToastManager,
    LoadingSpinner
  },
  setup(_, {root}) {
    const store = root.$store;

    /** Loading indicator **/
    const isLoading = computed(() => store.getters.isPageLoading);
    const hideLoadingSpinner = computed(() => root.$route.meta.hideLoadingSpinner);

    return {
      /** loading indicator **/
      isLoading,
      hideLoadingSpinner
    }
  }
}
</script>

<style lang="scss">
@import "assets/css/layout.shared";
</style>