export default class JiraProject {
  constructor({id, key, name}) {
    this.id = id;
    this.key = key;
    this.name = name;
  }

  isInArray(array) {
    return array.findIndex(item => Number(item.id) === Number(this.id)) !== -1;
  }

  static parseDataFromObject(data) {
    return new JiraProject(data);
  }
}
