const Layout = () => import('@/views/auth/Layout');
const Login = () => import('@/views/auth/Login.vue');
const CreatePassword = () => import('@/views/auth/CreatePassword.vue');
const ForgotPassword = () => import('@/views/auth/ForgotPassword.vue');

export const ROUTE_NAMES_AUTH = Object.freeze({
  AUTH: 'Auth',
  LOGIN: 'AuthLogin',
  CREATE_PASSWORD: 'AuthCreatePassword',
  FORGOT_PASSWORD: 'AuthForgotPassword',
});

export const auth = {
  path: '/auth',
  name: ROUTE_NAMES_AUTH.AUTH,
  component: Layout,
  children: [
    {
      path: 'login',
      name: ROUTE_NAMES_AUTH.LOGIN,
      meta: {
        // guard: ...
        hideLoadingSpinner: true
      },
      component: Login
    },
    {
      path: 'reset-password/:id/:token',
      name: ROUTE_NAMES_AUTH.CREATE_PASSWORD,
      meta: {},
      component: CreatePassword
    },
    {
      path: 'forgot-password',
      name: ROUTE_NAMES_AUTH.FORGOT_PASSWORD,
      meta: {},
      component: ForgotPassword
    },
  ]
};
