import AuthGuard from '../guards/auth';

const Layout = () => import('@/views/Layout');
const ActiveOrdersOverview = () => import('@/views/activeOrders/ActiveOrdersOverview');

export const ROUTE_NAMES_ACTIVE_ORDERS = Object.freeze({
  ACTIVE_ORDERS: 'ActiveOrdersOverview',
});

export const activeOrders = {
  path: '/active-orders/',
  component: Layout,
  meta: {
    guard: AuthGuard
  },
  children: [
    {
      path: '',
      name: ROUTE_NAMES_ACTIVE_ORDERS.ACTIVE_ORDERS,
      meta: {},
      component: ActiveOrdersOverview
    },
  ]
};
