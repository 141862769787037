export default class ConflictedProject {
  constructor({id, name}) {
    this.id = id;
    this.name = name;
  }

  static parseDataFromObject(data) {
    return new ConflictedProject(data);
  }
}
