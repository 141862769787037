import {getters} from './getters'
import {actions} from './actions'
import {mutations} from './mutations'

export const state = {
  /** Projects **/
  projects: [],
  selectedProject: null,

  /** Order **/
  orders: [],

  /** Unassigned logs **/
  unassignedLogs: null,

  /** Conflicted projects **/
  conflictedProjects: [],
}

const namespaced = false

export const project = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
