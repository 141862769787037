export const USER_ROLES = {
  GUEST: 1,
  SUPER_ADMIN: 2,
  ADMIN: 3,
  READ_ONLY: 4
}

export default class UserProfile {
  constructor({firstName, lastName}) {
    this.firstName = firstName;
    this.lastName = lastName;
  }

  get username() {
    return `${this.firstName} ${this.lastName}`;
  }

  static parseDataFromObject(data) {
    return new UserProfile(data);
  }
}