import LocalDataHandler from '@/utils/handlers/LocalDataHandler'
import BaseGuard from "@/router/guards/base";
import {USER_ROLES} from "@/models/users/User";

class SuperAdminGuard extends BaseGuard {
  constructor() {
    super();
    this.typeAllowed = USER_ROLES.SUPER_ADMIN;
  }

  async handleRouteEnter() {
    if (!LocalDataHandler.getAccessToken()) {
      return this.refreshAccessToken().then(response => {
        if (response) {
          return this.checkUserType() ? true : this.routeOnFailure;
        }
        /** keep in mind that the catch is handled in base guard (base.js) **/
        return this.routeOnFailure;
      });
    }
    return new Promise(resolve => {
      this.checkUserType() ? resolve(true) : resolve(this.routeOnFailure);
    });
  }

  checkUserType() {
    const userRole = LocalDataHandler.getUserRole();
    return !!userRole && userRole === this.typeAllowed;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new SuperAdminGuard();
    }
    return this.instance;
  }
}

export default SuperAdminGuard.getInstance();