import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const state = {
  /** Logs **/
  conflictedLogs: [],

  /** Issues **/
  conflictedIssues: [],
}

const namespaced = false

export const conflictManager = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
