import AuthGuard from '../guards/auth';

const Layout = () => import('@/views/Layout');
const Estimations = () => import('@/views/calculations/Estimations');
const Afterpay = () => import('@/views/calculations/Afterpay');

export const ROUTE_NAMES_CALCULATIONS = Object.freeze({
  CALCULATIONS: 'Calculations',
  CALCULATIONS_ESTIMATIONS: 'CalculationsEstimations',
  CALCULATIONS_AFTERPAY: 'CalculationsAfterpay',
});

export const calculations = {
  path: '/calculations',
  component: Layout,
  meta: {
    guard: AuthGuard
  },
  children: [
    {
      path: 'estimations',
      name: ROUTE_NAMES_CALCULATIONS.CALCULATIONS_ESTIMATIONS,
      meta: {},
      component: Estimations
    },
    {
      path: 'afterpay',
      name: ROUTE_NAMES_CALCULATIONS.CALCULATIONS_AFTERPAY,
      meta: {},
      component: Afterpay
    },
  ]
};
