import apiHandler from '../../utils/handlers/ApiHandler';
import {SET_API_ERROR} from "../mutations";
import {SET_CONFLICTED_ISSUES, SET_CONFLICTED_LOGS} from "@/store/conflictManager/mutations";

/** Logs **/
export const GET_CONFLICTED_LOGS = 'getConflictedLogs';
export const RESOLVE_CONFLICTED_LOGS = 'resolveConflictedLogs';

/** Issues **/
export const GET_CONFLICTED_ISSUES = 'getConflictedIssues';
export const RESOLVE_CONFLICTED_ISSUES = 'resolveConflictedIssues';

export const actions = {
  getConflictedLogs({commit}, projectId) {
    return apiHandler.get(`projects/${projectId}/jira-conflicts/logs`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_CONFLICTED_LOGS, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      return false;
    });
  },
  resolveConflictedLogs({commit}, {projectId, logs}) {
    const data = {logs};
    return apiHandler.put(`projects/${projectId}/jira-conflicts/logs-resolve`, data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      return false;
    });
  },

  /** Issues **/
  getConflictedIssues({commit}, projectId) {
    return apiHandler.get(`projects/${projectId}/jira-conflicts/issues`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_CONFLICTED_ISSUES, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      return false;
    });
  },
  resolveConflictedIssues({commit}, {projectId, issues}) {
    const data = {issues};
    return apiHandler.put(`projects/${projectId}/jira-conflicts/issues-resolve`, data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      return false;
    });
  },
}
