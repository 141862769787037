import apiHandler from "@/utils/handlers/ApiHandler";
import {SET_API_ERROR} from "@/store/mutations";
import {downloadPdf} from "@/utils/globals";

export const EDIT_LOG = 'editLog';
export const ADJUST_RATIOS = 'adjustRatios';
export const UNASSIGN_LOGS = 'unassignLogs';
export const MARK_LOGS_AS_PROCESSED = 'markLogsAsProcessed';
export const EXPORT_PDF = 'exportPdf';

export const actions = {

  editLog({commit}, payload) {
    const data = {
      employeeId: payload.employeeId,
      issueId: payload.issueId,
      comment: payload.comment,
      ratio: payload.ratio,
      excludeReason: payload.excludeReason
    }

    return apiHandler.put(`logs/${payload.logId}`, data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  adjustRatios({commit}, payload) {
    return apiHandler.put('logs/adjust-ratios', payload, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  unassignLogs({commit}, payload) {
    const data = {
      logIds: payload.map(log => log.id),
    };

    return apiHandler.put('logs/unassign-logs', data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  markLogsAsProcessed({commit}, payload) {
    const data = {
      logIds: payload.map(log => log.id),
    };

    return apiHandler.put('logs/set-processed', data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
  exportPdf({commit}, payload) {
    const formData = new FormData();
    formData.append('title', payload.title);
    formData.append('startDate', payload.startDate);
    formData.append('endDate', payload.endDate);
    formData.append('deductedHours', payload.deductedHours);
    formData.append('outputStrategy', payload.outputStrategy);
    formData.append('fileName', payload.fileName);
    formData.append('search', payload.search);
    formData.append('orderId', payload.orderId);
    payload.ids.map((id, index) => {
      formData.append(`ids[${index}]`, id);
    });
    payload.employeeIds.map((id, index) => {
      formData.append(`employeeIds[${index}]`, id);
    });
    payload.issueIds.map((id, index) => {
      formData.append(`issueIds[${index}]`, id);
    });
    payload.departments.map((department, index) => {
      formData.append(`departments[${index}]`, department);
    });

    return apiHandler.post('export/pdf', formData, apiHandler.getAuthHeader(), null, true).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        // Get the filename from the header.
        const contentDisposition = response.headers['content-disposition'];
        const fileName = contentDisposition.match(/filename="(.+)"/)[1];

        // Download the PDF.
        downloadPdf(response.data, fileName);

        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      console.log(error);
      return false;
    })
  },
}
