export default class JiraDepartment {
  constructor(name) {
    this.name = name;
    this.id = `department-${name}`;
  }

  static parseDataFromObject(name) {
    return new JiraDepartment(name);
  }
}
