import {getters} from './getters'
import {actions} from './actions'
import {mutations} from './mutations'

export const state = {}

const namespaced = false

export const log = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
