import Worklog from "@/models/order/Worklog";
import OrderTypeOption from "@/models/order/OrderTypeOption";
import {isNumberFloat} from "@/utils/globals";


export default class OrderDetails {
  constructor({availableHours, completed, endDate, id, type, projectId, spentHours, startDate, title, logs, totalSpentHours, isBillable, totalReductedHours, reductedHours}) {
    this.availableHours = availableHours;
    this.completed = completed;
    this.endDate = endDate;
    this.id = id;
    this.type = OrderTypeOption.parseDataFromObject(type);
    this.projectId = projectId;
    this.spentHours = spentHours;
    this.startDate = startDate;
    this.title = title;
    this.logs = logs.map(log => Worklog.parseDataFromObject(log));
    this.totalSpentHours = totalSpentHours;
    this.isBillable = isBillable;
    this.totalReductedHours = totalReductedHours;
    this.reductedHours = reductedHours;
  }

  get totalSpentAdjusted() {
    return this.totalSpentHours - this.totalReductedHours;
  }

  get availableMinusSpent() {
    return Math.max(0, this.availableHours - (this.totalSpentHours - this.totalReductedHours));
  }

  // Get the percentage of the availableHours/totalSpentHours
  get availableByTotalSpent() {
    if (this.availableHours === 0) {
      return 0
    }
    const percentage = (this.totalSpentAdjusted / this.availableHours) * 100;
    return Number(percentage.toFixed(0));
  }

  // Get the percentage of the totalSpent/availableHours
  get filteredSpentByAvailableHours() {
    if (this.availableHours === 0) {
      return 0
    }
    const percentage = ((this.spentHours - this.reductedHours) / this.availableHours) * 100;
    return Math.max(0, Number(percentage.toFixed(0)));
  }

  /** Dates - Formatting for the date pickers **/
  get end() {
    return this.endDate ? this.endDate : '';
  }


  /** Filtered hours **/
  get totalFilteredHours() {
    return this.spentHours - this.reductedHours;
  }

  /** UI helpers **/

  /**
   * Formats the start and end date into one string divided by a '-'.
   * @return {string} - The formatted string.
   */
  get startAndEndDate() {
    // const startFixed = this.startDate.replace(/-/g, '/'); // Fixed for Safari.
    // const endFixed = this.endDate ? this.endDate.replace(/-/g, '/') : ""; // Fixed for Safari.
    const start = this.startDate;
    const end = this.endDate ? `- ${this.endDate}` : "";
    return `${this.startDate} ${end}`;
  }

  static parseDataFromObject(data) {
    return new OrderDetails(data);
  }
}
