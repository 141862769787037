import OrderTypeOption from "@/models/order/OrderTypeOption";

export default class Order {
  constructor({availableHours, completed, id, isBillable, orderType, spentHours, title}) {
    this.availableHours = availableHours;
    this.completed = completed;
    this.id = id;
    this.isBillable = isBillable;
    this.orderType = OrderTypeOption.parseDataFromObject({id: orderType.id, type: orderType.type});
    this.spentHours = spentHours;
    this.title = title;
  }

  get billable() {
    return this.isBillable === 1;
  }

  get orderTypeAndTitle() {
    return `${this.orderType.type}: ${this.title}`;
  }

  /**
   * Gets the progress data for the OrderCard component.
   * @return {{percentage: number, availableHours: number, spentHours: number}}
   */
  get progressData() {
    return {
      spentHours: this.spentHours,
      availableHours: this.availableHours,
      percentage: this.progressInPercentage
    }
  }

  /**
   * Gets the progress of the order in percentage form.
   * @return {number}
   */
  get progressInPercentage() {
    if (this.availableHours === 0) {
      return 0;
    }
    const percentage = ((this.spentHours / this.availableHours) * 100).toFixed(2);
    return Number(percentage);
  }

  static parseDataFromObject(data) {
    return new Order(data);
  }
}
