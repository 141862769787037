import {startsWithVowel} from "@/utils/globals";

export const ORDER_TYPE_IDS = {
  PUNCH_CARD: 1,
  ESTIMATE: 2,
  AFTER_PAY: 3,
  SERVICE_LEVEL_AGREEMENT: 4,
  UNASSIGN: 5,

  MARK_AS_PROCESSED: 150,
}

export default class OrderTypeOption {
  constructor(id, type) {
    this.id = id;
    this.type = type;
  }

  get color() {
    if (this.id === ORDER_TYPE_IDS.PUNCH_CARD) {
      return 'purple';
    } else if (this.id === ORDER_TYPE_IDS.ESTIMATE) {
      return 'brown';
    } else if (this.id === ORDER_TYPE_IDS.AFTER_PAY) {
      return 'light-blue';
    } else if (this.id === ORDER_TYPE_IDS.UNASSIGN) {
      return 'yellow';
    } else if (this.id === ORDER_TYPE_IDS.SERVICE_LEVEL_AGREEMENT) {
      return 'orange';
    } else if (this.id === ORDER_TYPE_IDS.MARK_AS_PROCESSED) {
      return 'gray';
    }
    return 'purple';
  }

  // Checks if the type starts with a vowel in order to provide the grammatically correct article (a or an).
  get article() {
    return startsWithVowel(this.type) ? 'An' : 'A';
  }

  static parseDataFromObject(data) {
    return new OrderTypeOption(data.id, data.type);
  }
}
