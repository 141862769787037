import Vue from 'vue';
import './setup/composition-api';
import App from './App.vue';
import router from './router';
import store from './store';
import apiHandler from "./utils/handlers/ApiHandler";

/** Initialize the toast handler. **/
import "./utils/handlers/toastHandler/ToastHandler";

/** Set up the api interceptors **/
apiHandler.setInterceptors(store);

/** Set up the router reference **/
apiHandler.setRouter(router);

Vue.config.productionTip = false;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
