export const getters = {

  /** Order type **/
  getOrderTypes(state) {
    return state.orderTypes;
  },

  /** Order details **/
  getSelectedOrder(state) {
    return state.selectedOrder;
  },
}
