import {ROUTE_NAMES_PROJECT_OVERVIEW} from "@/router/modules/projectOverview";
import {ROUTE_NAMES_ORDER} from "@/router/modules/order";
import AuthGuard from '../guards/auth';

const Layout = () => import('@/views/Layout');
const UnassignedLogs = () => import('@/views/manageLogs/UnassignedLogs');

export const ROUTE_NAMES_MANAGE_LOGS = Object.freeze({
  UNASSIGNED_LOGS: 'UnassignedLogs',
});

export const manageLogs = {
  path: '/project/order/:projectId/manage-logs',
  component: Layout,
  meta: {
    guard: AuthGuard
  },
  children: [
    {
      path: 'unassigned-logs',
      name: ROUTE_NAMES_MANAGE_LOGS.UNASSIGNED_LOGS,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_ORDER.ORDER_OVERVIEW,

        breadcrumb: [
          {
            name: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW,
            text: 'project overview'
          },
          {
            name: ROUTE_NAMES_ORDER.ORDER_OVERVIEW,
            textWithName: (projectName = "") => `project: ${projectName}`
          },
          {
            name: ROUTE_NAMES_MANAGE_LOGS.UNASSIGNED_LOGS,
            text: 'unassigned logs'
          },
        ],
      },
      component: UnassignedLogs
    },
  ]
};
