import {getters} from './getters'
import {actions} from './actions'
import {mutations} from './mutations'

export const state = {
  activeOrders: [],
}

const namespaced = false

export const activeOrders = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
