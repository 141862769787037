import Vue from 'vue';
import VueRouter from 'vue-router';
import modules from './modules';
import {ROUTE_NAMES_PROJECT_OVERVIEW} from "./modules/projectOverview";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: modules,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {x: 0, y: 0}
  }
});

router.beforeEach(async (to, from, next) => {
  // User enters an invalid URL.
  if (to.matched.length === 0) {
    return next({ name: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW });
  }

  // User enters a valid URL.
  for (const route of to.matched) {
    if (route.meta && route.meta.guard) {
      /** Let each guard handle route access **/
      const result = await route.meta.guard.handleRouteEnter();
      if (result !== true) {
        return next({ name: result });
      }
    }
  }

  return next();
});

export default router;
