import apiErrorMsgHandler from "@/utils/handlers/ApiErrorMsgHandler";

/** Modal **/
export const SET_MODAL_OPTIONS = 'setModalOptions';
export const RESET_MODAL_OPTIONS = 'resetModalOptions';
export const SET_ACTIVE_MODAL = 'setActiveModal';

/** API ERROR **/
export const SET_API_ERROR = 'setApiError';
export const RESET_API_ERROR = 'resetApiError';

/** Loading spinner **/
export const SET_LOADING = 'setLoading';

/** Pagination **/
export const SET_PAGINATION_DATA = 'setPaginationData';
export const RESET_PAGINATION_DATA = 'resetPaginationData';

/** Menu **/
export const SET_SELECTED_MENU_ID = 'setSelectedMenuId';

export const mutations = {
  /** Modal **/
  setModalOptions(state, {modalContent, activeModal}) {
    state.modalContent = modalContent;
    state.activeModal = activeModal;
    state.isModalShown = true;
  },
  resetModalOptions(state) {
    state.modalContent = null;
    state.isModalShown = false;
    state.activeModal = null;
  },
  setActiveModal(state, activeModal) {
    state.activeModal = activeModal;
  },

  /** API Error **/
  setApiError(state, error) {
    state.apiError = apiErrorMsgHandler.convert(error);
  },
  resetApiError(state) {
    state.apiError = '';
  },

  /** Loading spinner **/
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },

  /** Pagination **/
  setPaginationData(state, data) {
    state.paginationData = data;
  },
  resetPaginationData(state) {
    state.paginationData = null;
  },

  /** Menu **/
  setSelectedMenuId(state, data) {
    state.selectedMenuId = data;
  },
}
