import ConflictedLog from "@/models/conflictManager/ConflictedLog";

export default class OrderConflictedLogs {
  constructor({id, logs, title, type}) {
    this.id = id;
    this.logs = logs.map(log => ConflictedLog.parseDataFromObject({...log, orderType: type, title}));
    this.title = title;
    this.type = type;
  }

  static parseDataFromObject(data) {
    return new OrderConflictedLogs(data);
  }
}
