import {getters} from './getters'
import {actions} from './actions'
import {mutations} from './mutations'

export const state = {

  /** Order type **/
  orderTypes: [],

  /** Order details **/
  selectedOrder: null
}

const namespaced = false

export const order = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
