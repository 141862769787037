export const getters = {

  /** Modal **/
  getModalOptions(state) {
    return state.modalContent;
  },
  isModalShown(state) {
    return state.isModalShown;
  },
  getActiveModal(state) {
    return state.activeModal;
  },

  /** API Error**/
  getApiError(state) {
    return state.apiError;
  },

  /** Loading spinner*/
  isPageLoading(state) {
    return state.isLoading
  },

  /** Pagination **/
  getPaginationData(state) {
    return state.paginationData;
  },

  /** Menu **/
  getSelectedMenuId(state) {
    return state.selectedMenuId;
  },
}
