export default class JiraIssue {
  constructor({summary, key, id}) {
    this.summary = summary;
    this.key = key;
    this.id = id;
  }

  /** UI Helpers **/

  // SearchResult
  get searchResultItem() {
    return {name: this.key, id: this.id};
  }

  static parseDataFromObject(data) {
    return new JiraIssue(data);
  }
}
