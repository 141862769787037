import ConflictChange, {ISSUE_CONFLICT_CHANGES} from "@/models/conflictManager/change/ConflictChange";

export default class IssueConflictChange extends ConflictChange {
  constructor(change) {
    super(change);
  }

  get changeClass() {
    return `change-${this.change.toLowerCase()}`;
    // return `change-${ISSUE_CONFLICT_CHANGES[this.change]}`;
  }

  static parseDataFromObject(data) {
    return new IssueConflictChange(data);
  }
}
