import IssueConflictChange from "@/models/conflictManager/change/IssueConflictChange";
import ConflictDetails from "@/models/conflictManager/ConflictDetails";
import OrderTypeOption from "@/models/order/OrderTypeOption";

export default class OrderConflictedIssue {
  constructor({changes, date, details, id, key, link, orders}) {
    this.changes = changes.map(change => IssueConflictChange.parseDataFromObject(change));
    this.date = date;
    this.details = this.setDetails(details, id);
    this.id = id;
    this.key = key;
    this.link = link;
    this.orders = orders.map(order => {
      return {
        title: order.title,
        type: OrderTypeOption.parseDataFromObject(order.type)
      }
    });
  }

  setDetails(details, id) {
    return Object.keys(details).map(key => ConflictDetails.parseDataFromObject({
      newVersion: details[key].new,
      old: details[key].old,
      type: key,
      conflictId: id
    }));
  }

  static parseDataFromObject(data) {
    return new OrderConflictedIssue(data);
  }
}
