import apiHandler from '../../utils/handlers/ApiHandler';
import {SET_API_ERROR} from "../mutations";
import {SET_USER_PROFILE, SET_USERS} from "./mutations";

export const GET_USER_PROFILE = 'getUserProfile';

/** User management **/
export const GET_USERS = 'getUsers';
export const CREATE_USER = 'createUser';
export const EDIT_USER = 'editUser';
export const DELETE_USERS = 'deleteUsers';
export const SET_USER_ROLE = 'setUserRole';

export const actions = {
  getUserProfile({commit}) {
    return apiHandler.get('user/profile', apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_USER_PROFILE, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      return false;
    });
  },

  /** User management **/
  getUsers({commit}, {searchTerm, roleId, sortOrder}) {
    return apiHandler.get(`user/list?searchTerm=${searchTerm}&roleId=${roleId}&sortOrder=${sortOrder}`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_USERS, response.data);
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      return false;
    });
  },
  createUser({commit}, data) {
    return apiHandler.post('user/create', data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      return false;
    });
  },
  editUser({commit}, {userId, userData}) {
    const data = {
      "firstName": userData.firstName,
      "lastName": userData.lastName,
      "email": userData.email,
      "roleId": userData.roleId
    }

    return apiHandler.put(`user/${userId}`, data, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      return false;
    });
  },
  deleteUsers({commit}, userIds) {
    const ids = {
      userIds
    }
    return apiHandler.delete('user/delete', ids, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      return false;
    });
  },
  setUserRole({commit}, {userIds, roleId}) {
    const payload = {
      userIds,
      roleId
    }
    return apiHandler.put('user/bulk-role', payload, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      commit(SET_API_ERROR, error.response.data);
      return false;
    });
  }
}
