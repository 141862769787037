export const getters = {
  /** Projects **/
  getAllProjects(state) {
    return state.projects;
  },
  getSelectedProject(state) {
    return state.selectedProject;
  },

  /** Order **/
  getOrders(state) {
    return state.orders;
  },

  /** Unassigned logs **/
  getUnassignedLogs(state) {
    return state.unassignedLogs;
  },

  /** Conflicted projects **/
  getConflictedProjects(state) {
    return state.conflictedProjects;
  }
}
