export function stopPropagation(ev) {
  event.stopPropagation()
}

export function startsWithVowel(str) {
  var vowels = ("aeiouAEIOU");
  return vowels.indexOf(str[0]) !== -1;
}

export function parentContainsElement(event, parentId) {
  return document.getElementById(parentId).contains(event.target);
}

export function hasParentWithMatchingSelector(target, selector) {
  return [...document.querySelectorAll(selector)].some(el => {
      return el !== target && el.contains(target);
    }
  )
}

export function isPositiveNumber(num) {
  const regNumeric = /^\d*[1-9]\d*$/;
  return regNumeric.test(num);
}

export function isPositiveOrZeroNumber(num) {
  const regNumeric = /^\d*[0-9]\d*$/;
  return regNumeric.test(num);
}

export function downloadPdf(pdf, fileName) {
  let url = window.URL.createObjectURL(new Blob([pdf], {type: 'application/pdf'}));
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}`); //or any other extension
  document.body.appendChild(link);
  link.click();
}

export function isNumberFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function arraysAreEqual(a1, a2) {
  // WARNING: arrays must not contain {objects} or behavior may be undefined.
  return JSON.stringify(a1) == JSON.stringify(a2);
}

/**
 * Converts a string to plural by adding an -s. The string needs to be a regular noun.
 * @param {number} count - The number to check in order to pluralize or not.
 * @param {string} singularString - The string that needs to be pluralized.
 * @return {string} - The converted string.
 */
export function pluralizeRegularString(count, singularString) {
  return count > 1 ? `${singularString}s` : singularString;
}

/**
 * Checks and returns if a string is either null OR empty OR just whitespace only.
 * @param {string} str - The string that will be checked.
 * @return {boolean}
 */
export function isEmptyOrSpaces(str) {
  return str === null || str === "" || str.match(/^ *$/) !== null;
}

/**
 * Adds an event listener.
 * @param {string} type - The type of the EventListener.
 * @param {function} listenerFunction - The function of the listener.
 */
export function addListener(type, listenerFunction) {
  document.addEventListener(type, listenerFunction);
}

/**
 * Removes an event listener.
 * @param {string} type - The type of the EventListener.
 * @param {function} listenerFunction - The function of the listener.
 */
export function removeListener(type, listenerFunction) {
  document.removeEventListener(type, listenerFunction);
}
