export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

/**
 * Extracts the day, month and year out of a date.
 * @param {Date} aDate - The date that the data will be extracted from.
 * @param {boolean} fixZeroIndex
 * @return {{month: number, year: number, day: number}}
 */
export function getYearMonthDay(aDate, fixZeroIndex = false) {
  const year = aDate.getFullYear();
  let month = fixZeroIndex ? aDate.getMonth() + 1 : aDate.getMonth(); // +1 because it's zero-indexed
  if (month < 10) {
    month = `0${month}`;
    // month = parseInt(`0${month}`);
  }

  let day = aDate.getDate();
  if (day < 10) {
    day = `0${day}`;
    // day = parseInt(`0${day}`);
  }

  return {day, month, year};
}

export function getToday() {
  // Get the date today.
  const date = new Date();

  // Get the day, month and year.
  const {day, month, year} = getYearMonthDay(date, true);
  return `${day}-${month}-${year}`;
}

export function getDateXDaysAgo(days) {
  // Get the date today.
  const date = new Date();

  let last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
  let day = last.getDate();
  let month = last.getMonth() + 1;
  let year = last.getFullYear();

  if (month < 10) {
    month = `0${month}`;
  }

  if (day < 10) {
    day = `0${day}`;
  }
  return `${day}-${month}-${year}`
}

/**
 *
 * @param {Date} aDate
 * @return {string}
 */
export function formatIntoDayMonthYear(aDate) {
  const {year, month, day} = getYearMonthDay(aDate);
  return `${day} ${months[month]} ${year}`;
}

export function formatIntoYMD(aDate) {
  const {year, month, day} = getYearMonthDay(aDate);
  return `${year}/${month}/${day}`;
}

export function formatIntoDashedDMY(aDate) {
  const {year, month, day} = getYearMonthDay(aDate, true);
  return `${day}-${month}-${year}`;
}
