import Vue from 'vue'
import Vuex from 'vuex'
import {auth} from "./auth";
import {order} from "./order";
import {log} from "./log";
import {project} from "./project";
import {mutations} from "./mutations";
import {actions} from "./actions";
import {getters} from "./getters";
import {user} from "./user";
import {jira} from "./jira";
import {conflictManager} from "./conflictManager";
import {activeOrders} from "@/store/activeOrders";

Vue.use(Vuex)

export const state = {
  apiError: null,

  /** Modal **/
  isModalShown: false,
  modalContent: null,
  activeModal: null,

  /** Loading spinner **/
  isLoading: false,

  /** Pagination **/
  paginationData: null,

  /** Menu **/
  selectedMenuId: null,
}

const store = {
  state,
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {
    auth,
    order,
    log,
    project,
    user,
    jira,
    conflictManager,
    activeOrders,
  },
};

const vuexStore = new Vuex.Store(store);

export default vuexStore;
