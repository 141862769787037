import Log from "./Log";

export default class UnassignedLogs {
  constructor({sinceDate, totalHours, workLogs}) {
    this.sinceDate = sinceDate;
    this.totalHours = totalHours;
    this.workLogs = workLogs ? workLogs.map(log => Log.parseDataFromObject(log)) : [];
  }

  get noWorkLogsAvailable() {
    return this.logCount === 0;
  }

  get logCount() {
    return this.workLogs.length;
  }

  static parseDataFromObject(data) {
    return new UnassignedLogs(data);
  }
}
