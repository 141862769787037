/**
 * @enum for the different error types.
 */
export const ERR_TYPE = {
  INVALID_GRANT: 'invalid_grant',
  DUPLICATE_RECORD: 'duplicate_record',
  CONFLICT_ORDER_WITH_LOGS_ASSIGNED_TO_JIRA_PROJECT: 'conflict_order_with_logs_assigned_to_jira_project',
  CONFLICT_EMAIL_IN_USE: 'conflict_email_in_use',
  FAILED_TO_SEND_MAIL: 'failed_to_send_mail',
  PROJECT_NOT_FOUND: 'not_found_project',
  ORDER_NOT_FOUND: 'not_found_order',
};

/**
 * The API returns error messages in the following format: {TYPE}_{FIELD}.
 * This class contains functions to process this error message to, for example, render a proper user-readable error message instead.
 */
class ApiErrorMsgHandler {
  /**
   * @type ApiErrorMsgHandler
   */
  static instance;

  constructor() {
  }

  /**
   * Takes in an API error string and transforms it so that it is more readable/useful.
   */
  convert(error) {
    console.log(error)
    const errorCode = error.error;
    const errorDescription = error.error_description;

    let message = '';
    console.log(errorCode)
    if (errorCode === ERR_TYPE.INVALID_GRANT) {
      message = 'E-mailadres en/of wachtwoord is onjuist! Probeer het opnieuw.';
    } else if (errorCode === ERR_TYPE.DUPLICATE_RECORD) {
      message = 'Dit e-mailadres is al in gebruik.';
    } else if (errorCode === ERR_TYPE.CONFLICT_ORDER_WITH_LOGS_ASSIGNED_TO_JIRA_PROJECT) {
      // message = 'These are orders that contain worklogs belonging to the Jira project. Remove these from your orders before continuing.';
      message = ERR_TYPE.CONFLICT_ORDER_WITH_LOGS_ASSIGNED_TO_JIRA_PROJECT;
    } else if (errorCode === ERR_TYPE.CONFLICT_EMAIL_IN_USE) {
      message = 'This email already belongs to a user.';
    } else if (errorCode === ERR_TYPE.FAILED_TO_SEND_MAIL) {
      message = 'The mail was not sent. Please try again later.';
    } else if (errorCode === ERR_TYPE.PROJECT_NOT_FOUND) {
      message = ERR_TYPE.PROJECT_NOT_FOUND;
    } else if (errorCode === ERR_TYPE.ORDER_NOT_FOUND) {
      message = ERR_TYPE.ORDER_NOT_FOUND;
    }

    return message;
  }

  /**
   * @returns {ApiErrorMsgHandler}
   */
  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiErrorMsgHandler();
    }
    return this.instance;
  }
}

/** @type {ApiErrorMsgHandler} **/
const apiErrorMsgHandler = ApiErrorMsgHandler.getInstance();

export default apiErrorMsgHandler;
