export const CONFLICT_TYPES= {
  SUMMARY: 'summary',
  PARENT: 'parentKey',
}

export default class ConflictDetails {
  constructor(newVersion, old, type, conflictId) {
    this.new = newVersion;
    this.old = old;
    this.type = type;
    this.conflictId = conflictId;
  }

  get roundedNew() {
    if (typeof this.new === 'number') {
      const percentage = this.new.toFixed(2);
      return Number(percentage);
    }
    return this.new
  }

  static parseDataFromObject(data) {
    return new ConflictDetails(data.newVersion, data.old, data.type, data.conflictId);
  }
}
